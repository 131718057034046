/* eslint-disable no-unused-vars */
<template>
<div v-if="usuario == undefined">
  <router-view />
</div>
<div v-else>
  <nav-bar/>
  <aside-menu :menu="menu"/>
  <router-view />
  <footer-bar/>
  <overlay v-show="isAsideLgActive" z-index="z-30" @overlay-click="overlayClick" />
</div>
</template>

<script>
// @ is an alias to /src
import { computed, /* onBeforeMount, */ ref } from 'vue'
import { useStore } from 'vuex'
// import { firebase } from '@/firebase'
import menu from '@/menu.js'
import NavBar from '@/components/NavBar.vue'
import AsideMenu from '@/components/AsideMenu.vue'
import FooterBar from '@/components/FooterBar.vue'
import Overlay from '@/components/Overlay.vue'
// import { useRoute, useRouter } from 'vue-router'
// import RouteNames from './router/routeNames'

export default {
  name: 'Home',
  components: {
    Overlay,
    FooterBar,
    AsideMenu,
    NavBar
  },
  setup () {
    const store = useStore()
    // const route = useRoute()
    // router = useRouter()
    const loading = ref(false)
    // const { LOGIN, HOME } = RouteNames

    /**
     * @param {firebase.User} user
    */
    /* const verifyUserFirebase = async (user) => {
      if (!user) return

      try {
        await store.dispatch('auth/getUserCredentials', user.uid)
      } catch (e) {
        console.error('Error ', e)
      } finally {
        loading.value = false
      }
    }

    onBeforeMount(() => {
      firebase.auth().onAuthStateChanged(async user => await verifyUserFirebase(user))
    }) */

    const isAsideLgActive = computed(() => store.state.isAsideLgActive)

    const overlayClick = () => {
      store.dispatch('asideLgToggle', false)
    }

    const usuario = computed(() => store.getters['auth/llamarUsuario'])
    console.log('USUARIO es:' + usuario.value)

    return {
      menu,
      isAsideLgActive,
      overlayClick,
      loading,
      usuario
    }
  }
}/* eslint-enable no-unused-vars */
</script>
