import { createRouter, createWebHashHistory } from 'vue-router'
// import Home from '@/views/Home.vue'
import RouteNames from './routeNames'
import { firebase } from '@/firebase'
import store from '@/store'

const routes = [
  /* {
    meta: {
      title: 'Dashboard',
      requiresAuth: true
    },
    path: '/',
    name: RouteNames.HOME,
    component: Home
  }, */
  {
    meta: {
      title: 'Inicio',
      requiresAuth: true
    },
    path: '/',
    name: 'inicio',
    component: () => import(/* webpackChunkName: "tables" */ '@/vistas/Inicio.vue')
  },
  {
    meta: {
      title: 'Planes',
      requiresAuth: true
    },
    path: '/planes/:idReservacion',
    name: 'planes',
    component: () => import(/* webpackChunkName: "tables" */ '@/vistas/planes.vue')
  },
  {
    meta: {
      title: 'Cuotas',
      requiresAuth: true
    },
    path: '/cuotas/:idPlan',
    name: 'cuotas',
    component: () => import(/* webpackChunkName: "tables" */ '@/vistas/cuotas.vue')
  },
  {
    meta: {
      title: 'Pagos',
      requiresAuth: true
    },
    path: '/pagos/:idCuota',
    name: 'pagos',
    component: () => import(/* webpackChunkName: "tables" */ '@/vistas/pagos.vue')
  },
  {
    meta: {
      title: 'Tables',
      requiresAuth: true
    },
    path: '/tables',
    name: 'tables',
    component: () => import(/* webpackChunkName: "tables" */ '@/views/Tables.vue')
  },
  {
    meta: {
      title: 'Forms',
      requiresAuth: true
    },
    path: '/forms',
    name: 'forms',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/Forms.vue')
  },
  {
    meta: {
      title: 'Profile',
      requiresAuth: true
    },
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue')
  },
  {
    meta: {
      title: 'Ui',
      requiresAuth: true
    },
    path: '/ui',
    name: RouteNames.UI,
    component: () => import(/* webpackChunkName: "ui" */ '@/views/Ui.vue')
  },
  {
    meta: {
      title: 'Responsive layout',
      requiresAuth: true
    },
    path: '/responsive',
    name: RouteNames.RESPONSIVE,
    component: () => import(/* webpackChunkName: "responsive" */ '@/views/Responsive.vue')
  },
  {
    meta: {
      title: 'Login',
      fullScreen: true
    },
    path: '/login',
    name: RouteNames.LOGIN,
    component: () => import(/* webpackChunkName: "login" */ '@/vistas/Login.vue')
  },
  {
    meta: {
      title: 'Error',
      fullScreen: true
    },
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const { LOGIN } = RouteNames
  const isLogin = to.name === LOGIN
  console.log(to.name)

  if (requiresAuth) {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) return next('/login')
      await store.dispatch('auth/getUserCredentials', user.uid)
      if (isLogin) return next({ name: 'inicio' })

      next()
    })
  } else {
    if (isLogin) {
      firebase.auth().onAuthStateChanged(async (user) => {
        // await store.dispatch('auth/getUserCredentials', user.uid)
        if (!user) return next()
        return next({ name: 'inicio' })
      })
    } else {
      next()
    }
  }
})

export default router
