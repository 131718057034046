const PRODUCCION = false

let FIREBASE_CONFIG

if (!PRODUCCION) {
  FIREBASE_CONFIG = {
    apiKey: 'AIzaSyAzKD6okNKaKj3UfWGpnV7ulvCVInaLinE',
    authDomain: 'class-portal-clientes.firebaseapp.com',
    projectId: 'class-portal-clientes',
    storageBucket: 'class-portal-clientes.appspot.com',
    messagingSenderId: '596811617357',
    appId: '1:596811617357:web:dbda8fab33c00586a73804',
    measurementId: 'G-TM6KDTMKHJ'
    /* apiKey: 'AIzaSyA06lCLnCBf-szysUm3vqmwVLyaCqUtn2k',
    authDomain: 'vida-ok-test.firebaseapp.com',
    projectId: 'vida-ok-test',
    storageBucket: 'vida-ok-test.appspot.com',
    messagingSenderId: '12473450153',
    appId: '1:12473450153:web:c42dc8bde84fa61896b3b8',
    measurementId: 'G-EF0K5F1CG5' */
  }
}

export {
  FIREBASE_CONFIG,
  PRODUCCION
}
