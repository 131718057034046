/* eslint-disable space-before-blocks */
export default {
  llamarCorporativo (state){
    return state?.user?.corporativo
  },
  llamarUsuario (state){
    return state?.user?.idUser
  },
  getPlanesPago (state){
    return state?.indice?.PlanesPago
  },
  getCuotasPlanesPago (state){
    return state?.indice?.CuotasPlanesPago
  },
  getPagoClientes (state){
    return state?.indice?.PagoClientes
  }
}
