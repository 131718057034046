export default {
  reservaciones (state) {
    return state.reservacion
  },
  planesPago (state) {
    return state.planPago
  },
  CuotaPlanesPago (state) {
    return state.CuotaPlanPago
  },
  Pagos (state) {
    return state.PagoClientes
  }
}
