/* eslint-disable */
import { db, firebase, FS_COLLECTIONS } from '@/firebase'

export default {

    /* async getApiReservacion({commit}){
        axios.get('http://localhost:5001/class-portal-clientes/us-central1/apiclass/v1/ClassDev/reservacion')
        .then(resp =>{
            console.log("RESERVACIONES: ",resp.data)
            return commit('SET_RESERVACION',resp.data)
        })
    } */
  /* async login ({ dispatch }, payload) {
    const { email, password } = payload
    const response = await firebase.auth().signInWithEmailAndPassword(email, password)

    return await dispatch('getUserCredentials', response.user.uid)
  },
  async getUserCredentials ({ commit }, userId) {
    const user = await handleGetFromFirebase(db.collection('Corporativos').doc('ClassDev').collection('Usuarios').doc(userId))

    if (user.perfil !== TIPE_USER.ADMIN) throw { code: 'auth/user-does-not-admin' }
    console.log('userId', userId)
    console.log('SET_USER', user)
    return commit('SET_USER', user)
  },
  async logout ({ commit }) {
    await firebase.auth().signOut()
    return commit('SET_USER', null)
  } */

  // RESERVACIONES

  async getReservaciones({ commit }, datos){
    const {idCorporativo, idUsuario} = datos;
    console.log("idCorporativo",idCorporativo);
    console.log("idUsuario",idUsuario);
    const reservaciones = await db.collection("Corporativos").doc(idCorporativo).collection("Usuarios").doc(idUsuario).collection("Reservaciones").get(); //db.collection("Corporativos").doc(idCorporativo).collection("Usuarios").doc(idUsuario).collection("Reservaciones")
    let arreglo = [];
    console.log("reservaciones: ",reservaciones);
    reservaciones.docs.forEach( res =>{
      arreglo.push(res.data())
    })
    console.log("RESERVACIONES",arreglo);
     commit('SET_RESERVACION',arreglo);
     return arreglo;
  },
  // PLANES DE PAGO
  async getPlanesPago({ commit }, datos){
    const {idCorporativo, idUsuario, idReservacion} = datos;
    console.log("idCorporativo",idCorporativo);
    console.log("idUsuario",idUsuario);
    console.log("idReservacion",idReservacion);
    const planesPago = await db.collection("Corporativos").doc(idCorporativo).collection("Usuarios").doc(idUsuario).collection("Reservaciones").doc(idReservacion).collection('PlanPagoUnidad').get(); //db.collection("Corporativos").doc(idCorporativo).collection("Usuarios").doc(idUsuario).collection("Reservaciones")
    let arreglo = [];
    console.log("planesPago: ",planesPago);
    planesPago.docs.forEach( res =>{
      arreglo.push(res.data())
    })
    console.log("planesPago",arreglo);
     commit('SET_PLANPAGO',arreglo);
     return arreglo;
  },

  // PLANES DE PAGO
  async getCuotasPlanesPago({ commit }, datos){
    const {idCorporativo, idUsuario, idReservacion,idPlanPago} = datos;
    // console.log("idCorporativo",idCorporativo);
    // console.log("idUsuario",idUsuario);
    // console.log("idReservacion",idReservacion);
    // console.log("idPlanPago",idPlanPago);
    
    console.log("idReservacion",idReservacion);
    const CuotasPlanesPago = await db.collection("Corporativos").doc(idCorporativo).collection("Usuarios").doc(idUsuario).collection("Reservaciones").doc(idReservacion).collection('PlanPagoUnidad').doc(idPlanPago).collection('CuotasPlanPagos').get(); //db.collection("Corporativos").doc(idCorporativo).collection("Usuarios").doc(idUsuario).collection("Reservaciones")
    let arreglo = [];
    //console.log("CuotasPlanesPago: ",CuotasPlanesPago);
    CuotasPlanesPago.docs.forEach( res =>{
      arreglo.push(res.data())
    })
    console.log("CuotasPlanesPago",arreglo);
     commit('SET_CUOTAPLANPAGO',arreglo);
     return arreglo;
  },

  // PAGOS
  async getPagos({ commit }, datos){
    const {idCorporativo, idUsuario, idReservacion,idPlanPago,idCuotaPlanPago} = datos;
     console.log("idCorporativo",idCorporativo);
     console.log("idUsuario",idUsuario);
     console.log("idReservacion",idReservacion);
     console.log("idPlanPago",idPlanPago);
    
     console.log("idCuotaPlanPago",idCuotaPlanPago);
    const Pagos = await db.collection("Corporativos").doc(idCorporativo).collection("Usuarios").doc(idUsuario).collection("Reservaciones").doc(idReservacion).collection('PlanPagoUnidad').doc(idPlanPago).collection('CuotasPlanPagos').doc(idCuotaPlanPago).collection('PagoClientes').get(); //db.collection("Corporativos").doc(idCorporativo).collection("Usuarios").doc(idUsuario).collection("Reservaciones")
    let arreglo = [];
    //console.log("Pagos: ",Pagos);
    Pagos.docs.forEach( res =>{
      arreglo.push(res.data())
    })
    console.log("Pagos",arreglo);
     commit('SET_PAGOS',arreglo);
     return arreglo;
  }
}
