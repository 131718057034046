/* eslint-disable */
<template>
  <footer v-show="isFooterBarVisible" class="bg-white py-2 px-6 dark:bg-gray-900">
    <level>
      <div class="text-center md:text-left" >
        <b>&copy; {{ year }}, <a href="" target="_blank">Portal Clientes Class</a>.</b>
      </div>
    </level>
  </footer>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Level from '@/components/Level.vue'
// import JbLogo from '@/components/JbLogo.vue'

export default {
  name: 'FooterBar',
  components: {
    Level
    // JbLogo
  },
  setup () {
    const store = useStore()

    const year = computed(() => new Date().getFullYear())

    const isFooterBarVisible = computed(() => !store.state.isFullScreen)

    return {
      year,
      isFooterBarVisible
    }
  }
}
</script>
