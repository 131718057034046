/* eslint-disable */
import { firebase } from '@/firebase'
/**
 * @param {firebase.firestore.DocumentReference} doc
 */

export const handleGetFromFirebase = async (doc) => {
  try {
    const document = await doc.get()

    if (!document.exists) throw 'Document does not not exist'

    return document.data()
  } catch (e) {
    throw e
  }
}
