import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { FIREBASE_CONFIG } from './environment'

firebase.initializeApp(FIREBASE_CONFIG)

const FS_COLLECTIONS = {
  USERS: 'Usuarios'
}

const db = firebase.firestore()

export {
  FS_COLLECTIONS,
  firebase,
  db
}
