export default {
  SET_RESERVACION (state, payload) {
    state.reservacion = payload
  },
  SET_PLANPAGO (state, payload) {
    state.planPago = payload
  },
  SET_CUOTAPLANPAGO (state, payload) {
    state.CuotaPlanPago = payload
  },
  SET_PAGOS (state, payload) {
    state.PagoClientes = payload
  }
}
