/* eslint-disable */
import { TIPE_USER } from '@/constants/user'
import { db, firebase, FS_COLLECTIONS } from '@/firebase'
import { handleGetFromFirebase } from '@/utils/firebase'
import axios from 'axios'

export default {
  async login ({ dispatch }, payload) {
    const { email, password } = payload
    const response = await firebase.auth().signInWithEmailAndPassword(email, password)

    //await dispatch('getApiReservacion');
    console.log("LOGIN Es: "+response)
    return await dispatch('getUserCredentials', response.user.uid)
  },
  async getUserCredentials ({ commit }, userId) {
    const user = await handleGetFromFirebase(db.collection('Corporativos').doc('ClassDev').collection('Usuarios').doc(userId))

    const indice = await handleGetFromFirebase(db.collection('Corporativos').doc('ClassDev').collection('Indices').doc(userId))

    if (user.perfil !== TIPE_USER.ADMIN) throw { code: 'auth/user-does-not-admin' }
    console.log('userId', userId);
    console.log('Indice:',indice);
    console.log('SET_USER', user);
    commit('SET_INDICE',indice);
    return commit('SET_USER', user);
  },
  /* async getApiReservacion({commit}){
    axios.get('http://localhost:5001/class-portal-clientes/us-central1/apiclass/v1/ClassDev/reservacion')
    .then(resp =>{
        console.log("RESERVACIONES: ",resp.data)
        return commit('SET_RESERVA',resp.data)
    })
  }, */
  async logout ({ commit }) {
    await firebase.auth().signOut()
    return commit('SET_USER', null)
  }
}
